<template>
  <div style="overflow-y: auto;height: 100%;width: 100%">
    <div class="yt-breadcrumb-wrapper">
      <el-breadcrumb class="yt-breadcrumb">
        <el-breadcrumb-item>数据分析</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/manage/analysis/exam' }">考试分析</el-breadcrumb-item>
        <el-breadcrumb-item>成绩分析</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div style="padding: 10px;width: 100%">
      <div style="display: flex; margin-bottom: 15px;width: 100%">
        <div class="card" style="position: relative">
          <div style="display: flex;align-items: center;justify-content: space-between">
            <div style="display: flex;align-items: center">
              <div class="title">知识点分布</div>
              <div class="light" style="margin-left: 13px">
                一个知识点分布雷达图
              </div>
            </div>
            <div style="color: #666666;font-size: 12px;float: right">题目总量：{{ questions.length }}</div>
          </div>
          <div id="know" style="width: 100%;height: 100%" />
        </div>
        <div class="card" style="margin-left: 15px">
          <div style="display: flex;align-items: center">
            <div class="title">考试正态分布</div>
            <div class="light" style="margin-left: 13px">一个考试正态分布图</div>
          </div>
          <div style="display: flex;margin-top: 15px">
            <div :class="tab === '折线图' ? 'tabActive' : 'tab'" @click="tabChange('折线图')">
              折线图
            </div>
            <div :class="tab !== '折线图' ? 'tabActive' : 'tab'" @click="tabChange('柱状图')" style="margin-left: 57px">
              柱状图
            </div>
          </div>
          <div class="hr"></div>
          <div v-show="tab === '折线图'" id="lineChart" style="width: 100%;height: 100%" />
          <div v-show="tab === '柱状图'" style="position: relative;width: 100%;height: 100%">
            <div style="display: flex;position: absolute;left: 40px;top: 20px">
              <img src="@assets/通过率@2x.png" width="16" height="16" />
              <div style="color: #888888;font-size: 13px;margin: 0 12px 0 4px">
                人数通过率
              </div>
              <div style="color: #568FEB;font-size: 14px">{{ passPeople }}%</div>
            </div>
            <!--          <div style="position: absolute;right: -18px;bottom: 40px;z-index: 10000">-->
            <!--            <div style="color: #3A4E64;font-size: 12px;font-weight: 400;margin-bottom: 8px">设置分数区间</div>-->
            <!--            <el-input-number size="mini" @on-change="barData" :min="10" v-model="area" :step="10"/>-->
            <!--          </div>-->
            <div id="barChart" style="width: 100%;height: 100%" />
          </div>
        </div>
      </div>

      <div class="bottom">
        <div style="width: calc(50% - 7px)">
          <div class="head" style="border-bottom: 1px solid #E6E6E6;box-shadow: inset -15px 0px 10px -15px #e6e6e6">
            题目
          </div>
          <div :style="{ 'max-height': listH + 'px', overflow: 'auto' }">
            <div
              :style="{
                'border-bottom': index !== questionIndex - 1 ? '1px solid #e6e6e6' : '1px solid white',
                'box-shadow': setShadow(index)
              }"
              @click="
                peopleData(q.questionId)
                questionIndex = index
              "
              :class="questionIndex === index ? 'listActive' : 'list'"
              v-for="(q, index) in questions"
              :key="index"
            >
              <div style="width: 100%">
                <div style="display: flex;align-items: center">
                  <img src="@assets/通过率@2x.png" width="16" height="16" />
                  <div class="name">通过率</div>
                  <div class="score">{{ Math.round(q.rightRate * 100) }}%</div>
                  <img style="margin-left: 50px" src="@assets/zuotirenshu@2x.png" width="16" height="16" />
                  <div class="name">做题人数</div>
                  <div class="score">{{ q.doCount }}</div>
                </div>
                <div v-html="q.stem" class="stem"></div>
                <component
                  :is="questionComponents[q.questionType]"
                  :question="q.question"
                  :readOnly="true"
                  :hasTool="true"
                  style="margin-top: 16px;text-align: left"
                ></component>
                <div class="correct-answer">
                  <div style="text-align: left;">正确答<span style="letter-spacing: 5px">案:</span></div>
                  <div style="display: flex;flex-wrap: wrap;white-space: normal" v-html="q.questionAnswer" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style="flex: 1;" v-loading="loading">
          <div class="head" style="border-bottom: 1px solid #F7F7F7">
            通过人员分布
          </div>
          <div class="table" style="font-weight: 400">
            <div style="width: 21%">姓名</div>
            <div style="width: 10%">分数</div>
            <!-- <div style="width: 20%">开始时间</div>
            <div style="width: 20%">结束时间</div> -->
            <div style="width: 49%">答案</div>
            <div style="width: 20%">考试用时</div>
          </div>
          <div :style="{ overflow: 'auto', 'max-height': listH - 50 + 'px' }">
            <div class="table" style="font-weight: 300" v-for="(p, index) in peoples" :key="index">
              <div style="width: 21%;display: flex;align-items:center;overflow: hidden">
                <el-avatar :src="p.avatar ? p.avatar : require('@assets/defaultAvatar.svg')" :size="30" />
                <span style="margin-left: 10px;white-space: nowrap">{{ p.userName }}</span>
              </div>
              <div style="width: 10%">{{ p.score || 0 }}</div>
              <div
                style="text-overflow: ellipsis;width: 49%;  display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;overflow: hidden;"
                :title="p.answer"
                v-html="p.answer"
              ></div>
              <!-- <div style="width: 20%">{{ p.submitTime | getTime }}</div> -->
              <div style="width: 19%">{{ formatSeconds(p.duration) }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import exam from '@api/exam'
import question from '@components/common/old/questionOld'
import util from '@util/util'
import TrueOrFalse from '@components/manage/question/list/questions/TrueOrFalse'
import Choice from '@components/manage/question/list/questions/Choice'
import Code from '@components/manage/question/list/questions/Code'

export default {
  name: 'ScoreDistribution',
  components: {
    TrueOrFalse,
    Choice,
    Code
  },
  data() {
    return {
      questionComponents: [TrueOrFalse, Choice, Choice, null, null, Code], //题目组件
      passPeople: 0,
      area: 30,
      loading: false,
      listH: document.body.clientHeight - 75,
      examId: '',
      questionIndex: 0,
      tab: '折线图',
      peoples: [],
      questions: [],
      line: {
        tooltip: {
          formatter: '{b0}分：{c0}人'
        },
        grid: {
          top: '50px',
          left: '0',
          right: '40px',
          bottom: '40px',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          name: '分数',
          data: []
        },
        yAxis: {
          minInterval: 1,
          type: 'value',
          name: '人数',
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          }
        },
        color: ['#5A8EF9'],
        series: [
          {
            data: [],
            type: 'line'
          }
        ]
      },
      bar: {
        tooltip: {
          formatter: '{b0}分：{c0}人'
        },
        grid: {
          top: '50px',
          left: '0px',
          right: '125px',
          bottom: '40px',
          containLabel: true
        },
        xAxis: {
          boundaryGap: true,
          axisTick: {
            alignWithLabel: true,
            interval: 0
          },
          axisLabel: {
            interval: 0
          },
          type: 'category',
          name: '分数',
          data: []
        },
        yAxis: {
          minInterval: 1,
          type: 'value',
          name: '人数'
        },
        color: ['#5A8EF9', '#80D1CD', '#ADD4FF', '#7484A1', '#EECD67'],
        series: [
          {
            data: [],
            type: 'bar'
          }
        ]
      },
      knowledge: {
        grid: {
          top: '34px',
          left: '8px',
          right: '20px',
          bottom: '50px',
          containLabel: true
        },
        tooltip: {},
        legend: {
          data: ['题目数量', '通过率']
        },
        color: ['#5A8EF9', '#80D1CD'],
        xAxis: {
          type: 'category',
          boundaryGap: true,
          axisTick: {
            alignWithLabel: true,
            interval: 0
          },
          data: []
        },
        yAxis: [
          {
            type: 'value',
            name: '题目数量',
            minInterval: 1
          },
          {
            type: 'value',
            name: '通过率',
            minInterval: 1
          }
        ],
        dataZoom: [
          {
            type: 'inside',
            endValue: 9,
            minValueSpan: 4,
            maxValueSpan: 14
          },
          {
            type: 'slider',
            showDetail: false
          }
        ],
        series: [
          {
            name: '题目数量',
            type: 'bar',
            data: []
          },
          {
            name: '通过率',
            type: 'bar',
            yAxisIndex: 1,
            data: []
          }
        ]
      }
    }
  },
  filters: {
    getTime(data) {
      let d = new Date(data)
      return util.dateTime(d)
    },
    setAnswer(data) {
      switch (data) {
        case 0:
          return '正确'
        case 1:
          return '错误'
        case 2:
          return '未做'
        case 3:
          return '不全对'
        case 4:
          return '需要批阅'
      }
    }
  },
  created() {
    this.examId = this.$handleParams('examAnalysisData').examId
  },
  mounted() {
    this.initData()
  },
  computed: {
    setShadow() {
      return function(index) {
        if (this.questionIndex === index) {
          if (this.questionIndex === 0) {
            return '0px 10px 10px -10px, 0px -12px 10px -10px #e6e6e6'
          } else if (this.questionIndex === this.questions.length - 1) {
            return '0px 14px 10px -10px #e6e6e6, 0px -10px 10px -10px'
          } else {
            return '0px 10px 10px -10px, 0px -10px 10px -10px'
          }
        } else {
          return 'inset -10px 0px 10px -10px #e6e6e6'
        }
      }
    }
  },
  methods: {
    tabChange(data) {
      this.tab = data
      if (data === '折线图') {
        this.lineData()
      } else {
        this.barData()
      }
    },
    initData() {
      // exam.getExamDetail(this.examId).then(res => {
      //   this.passPeople =
      //     Math.round((res.res.passCount / res.res.submitCount) * 100) || 0
      // })
      let knowChart = this.$echarts.init(document.getElementById('know'))
      question.getExamStatistic(this.examId).then(res => {
        if (res.res.length === 0) {
          this.knowledge.grid.left = '25px'
        }
        res.res.forEach(item => {
          this.knowledge.xAxis.data.push(item.knowledgeName || '暂未绑定知识点')
          this.knowledge.series[0].data.push(item.questionCount)
          this.knowledge.series[1].data.push(Math.round(item.passRate * 100))
        })
        this.knowledge && knowChart.setOption(this.knowledge)
      })
      question.getQuestionsStatistic(this.examId).then(res => {
        this.questions = res.res.map(item => {
          item.stem = this.cleanWord(item.stem)
          item.doCount = item.doCount || 0
          switch (item.questionType) {
            case 0:
              item['questionAnswer'] = item.question.answer ? '正确' : '错误'
              break
            case 1:
              item.question.options.forEach((res, index) => {
                if (res.correct) {
                  item['questionAnswer'] = String.fromCharCode(index + 65)
                }
              })
              break
            case 2:
              let array = item.question.options
                .filter(data => {
                  return data.correct
                })
                .map(res => {
                  return res.content
                })
              item['questionAnswer'] = array.toString()
              break
            case 3:
              item['questionAnswer'] = item.question.blanks
                .map(res => {
                  return res.blankAnswer[0]
                })
                .toString()
              break
            case 4:
              item['questionAnswer'] = item.question.answer
              break
            case 5:
              item['questionAnswer'] = '代码题不同语言有不同答案'
              break
          }
          return item
        })
        if (res.res.length !== 0) {
          this.peopleData(res.res[0].questionId)
        }
      })
      this.lineData()
    },
    lineData() {
      exam.getScoreByNum(this.examId).then(res => {
        let sum = 0
        res.res.forEach(item => {
          if (item.score) {
            this.line.xAxis.data.push(item.score)
            this.line.series[0].data.push(item.count)
            sum += item.score
          }
        })
        if (sum === 0) {
          this.line.grid.left = '20px'
        }
        this.$nextTick(() => {
          let lineChart = this.$echarts.init(document.getElementById('lineChart'))
          this.line && lineChart.setOption(this.line)
        })
      })
    },
    barData() {
      exam.getScoreByRange(this.examId, this.area).then(res => {
        this.passPeople = res.res.passRate ? this.$roundFloat(res.res.passRate * 100, 2) : 0
        this.bar.xAxis.data = []
        this.bar.series[0].data = []
        res.res.scoreAndCountVOS.forEach((item, index) => {
          this.bar.xAxis.data.push(item.leftScore + '-' + item.rightScore)
          this.bar.series[0].data.push({
            value: item.count,
            itemStyle: {
              color: this.bar.color[index % 5]
            }
          })
        })
        this.$nextTick(() => {
          let barChart = this.$echarts.init(document.getElementById('barChart'))
          this.bar && barChart.setOption(this.bar)
        })
      })
    },
    peopleData(id) {
      this.loading = true
      exam
        .getExamQuestionStatistic(this.examId, id)
        .then(res => {
          this.peoples = res.res.sort((a, b) => {
            return b.score - a.score
          })
          let array = this.questions.filter(data => {
            if (data.question.id === id) {
              return data
            }
          })[0]
          this.peoples.forEach(people => {
            switch (array.questionType) {
              case 0:
                people.answer = people.answer === 'true' ? '<p>正确</p>' : '<p>错误</p>'
                break
              case 1:
                array.question.options.forEach((option, index) => {
                  if (option.content === people.answer) {
                    people.answer = String.fromCharCode(index + 65)
                  }
                })
                break
              case 2:
                let arr = this.$replaceHtml(people.answer)
                people.answer = eval('(' + arr + ')').toString()
                break
              case 3:
                people.answer = this.setText(people.answer)
                break
              case 5:
                people.answer = this.setText(people.answer)
                break
            }
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    setText(data) {
      let codeText = ''
      const codeData = JSON.parse(data)
      if (codeData.answer) {
        for (let char of codeData.answer) {
          codeText += char
        }
      } else {
        let arr = Object.keys(codeData)
        for (let [index, key] of arr.entries()) {
          codeText += `${codeData[key]}${index < arr.length - 1 ? ',' : ''} `
        }
      }
      return codeText
    },
    formatSeconds(value) {
      if (value === 0) {
        return '小于一分钟'
      }
      let theTime = parseInt(value) // 需要转换的时间秒
      theTime = theTime * 60
      let theTime1 = 0 // 分
      let theTime2 = 0 // 小时
      let theTime3 = 0 // 天
      if (theTime > 60) {
        theTime1 = parseInt(theTime / 60)
        theTime = parseInt(theTime % 60)
        if (theTime1 > 60) {
          theTime2 = parseInt(theTime1 / 60)
          theTime1 = parseInt(theTime1 % 60)
          if (theTime2 > 24) {
            //大于24小时
            theTime3 = parseInt(theTime2 / 24)
            theTime2 = parseInt(theTime2 % 24)
          }
        }
      }
      let result = ''
      if (theTime > 0) {
        result = '' + parseInt(theTime) + '秒'
      }
      if (theTime1 > 0) {
        result = '' + parseInt(theTime1) + '分' + parseInt(theTime) + '秒'
      }
      if (theTime2 > 0) {
        result = '' + parseInt(theTime2) + '小时' + parseInt(theTime1) + '分'
      }
      if (theTime3 > 0) {
        result = '' + parseInt(theTime3) + '天' + parseInt(theTime2) + '小时'
      }
      return result
    }
  }
}
</script>

<style scoped lang="less">
.correct-answer {
  display: flex;
  /*align-items: center;*/
  justify-content: flex-start;
  white-space: nowrap;
  overflow: hidden;
}
.bottom {
  width: 100%;
  background: #ffffff;
  box-shadow: 0 0 5px #071b2919;
  border-radius: 3px;
  display: flex;
  .table {
    text-align: left;
    border-bottom: 1px solid #f7f7f7;
    display: flex;
    //justify-content: space-between;
    align-items: center;
    color: #333333;
    font-size: 14px;
    padding: 0 30px;
    height: 50px;
  }
  .head {
    height: 40px;
    display: flex;
    align-items: center;
    color: #2b343d;
    font-size: 14px;
    font-weight: 500;
    padding: 0 30px;
  }
  .list {
    background: rgba(252, 252, 252, 1);
    opacity: 0.8;
    padding: 16px 30px;
    display: flex;
    align-items: center;
    box-shadow: inset -10px 0px 10px -10px #e6e6e6;
    cursor: pointer;
  }
  .listActive {
    background: rgba(255, 255, 255, 1);
    opacity: 1;
    padding: 16px 30px;
    display: flex;
    align-items: center;
  }
  .name {
    font-size: 11px;
    font-weight: 400;
    color: rgba(136, 136, 136, 1);
    margin: 0 12px 0 4px;
  }
  .score {
    font-size: 12px;
    color: rgba(86, 143, 235, 1);
  }
  .stem {
    margin-top: 10px;
    font-size: 12px;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    word-break: break-word;
    word-break: break-all;
    text-align: left;
  }
}
.card {
  width: calc((100% - 15px) / 2);
  height: 480px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 5px rgba(7, 27, 41, 0.1);
  border-radius: 3px;
  padding: 30px;
  text-align: left;
  .title {
    font-size: 18px;
    color: rgba(0, 0, 0, 1);
  }
  .light {
    font-size: 12px;
    color: rgba(136, 136, 136, 1);
  }
  .tab {
    font-size: 14px;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    cursor: pointer;
    height: 30px;
  }
  .tabActive {
    height: 30px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(86, 143, 235, 1);
    cursor: pointer;
    border-bottom: 3px solid rgba(86, 143, 235, 1);
  }
  .hr {
    height: 2px;
    background: rgba(242, 243, 245, 1);
    margin: -2px -30px 0 -30px;
  }
}
</style>
